import useContracts from "./useContracts";
import { formatDistance } from "date-fns";
import { TestMasa, StkCorn } from "@masa-finance/smart-contracts";
import { useCallback, useEffect, useState } from "react";
import unit from "ethjs-unit";
import { decodeCornTransactionInput } from "../helpers/abi-decoder";

const baseURL = "https://alfajores-blockscout.celo-testnet.org/api";

const testMasaAddress = TestMasa.networks["44787"].address;
const stkCornAddress = StkCorn.networks["44787"].address;

const contractAddresses = {
  masa: testMasaAddress,
  stake: stkCornAddress,
};

export interface TransactionTypes {
  blockHash: string;
  blockNumber: string;
  from: string;
  to: string;
  gas: string;
  hash: string;
  input: string;
  timeStamp: string;
  gasUsed: string;
  success: boolean;
}

export const shortAddress = (address: string) => {
  const shortAddressStr =
    address?.substring(0, 2) + "..." + address?.substring(address?.length - 5);

  return shortAddressStr;
};

export const getCornFromDecodedInput = (decodedInput: {
  name: string;
  params: { name: string; value: string; type: string }[];
}) => {
  switch (decodedInput?.name) {
    case "stake":
      const amount = decodedInput.params.find(
        (p) => p.name === "amount"
      )?.value;
      if (amount) return unit.fromWei(amount || 0, "ether");
  }

  return null;
};

const transformTransaction = (t: TransactionTypes) => {
  const decodedInput = decodeCornTransactionInput(t.input);
  let method = decodedInput?.name;

  if (!method) {
    method = shortAddress(t.input);
  }

  const hasCorn = getCornFromDecodedInput(decodedInput);

  const timeStampDate = new Date(0);
  timeStampDate.setUTCSeconds(+t.timeStamp);

  const newTransaction = {
    from: shortAddress(t.from),
    fromHash: t.from,
    fromAddress: `/explorer/address/${t.from}`,
    to: shortAddress(t.to),
    toHash: t.to,
    contractName:
      t.to.toLowerCase() === testMasaAddress.toLowerCase() ? "Masa" : "Stake",
    contractAddress: `/explorer/address/${t.to}`,
    input: decodedInput,
    rawInput: t.input,
    blockNumber: t.blockNumber,
    blockHash: shortAddress(t.blockHash),
    blockAddress: `https://alfajores-blockscout.celo-testnet.org/block/${t.blockNumber}/transactions`,
    timeStamp: `${formatDistance(timeStampDate, new Date())} ago`,
    hashShort: shortAddress(t.hash),
    hash: t.hash,
    gas: unit.fromWei(t.gasUsed || 0, "Gwei"),
    corn: hasCorn,
    method,
    success: t.success,
  };

  return newTransaction;
};

export interface UseExplorerProps {
  contract?: "stake" | "masa";
  address?: string;
  page?: number;
  offset?: number;
  tx?: string;
}

export function useExplorer({
  contract = "masa",
  address,
  page,
  offset,
  tx,
}: UseExplorerProps) {
  const { corn } = useContracts();

  const [transactions, setTransactions] = useState([]);
  const [transaction, setTransaction] = useState<null | any>(null);

  const [celoBalance, setCeloBalance] = useState(undefined);
  const [cornBalance, setCornBalance] = useState(undefined);

  const fetchCornBalance = useCallback(
    async (address: string) => {
      const cBalance = await corn.methods.balanceOf(address).call();
      let cornAmount = unit.fromWei(cBalance || 0, "ether");
      cornAmount = parseFloat(cornAmount).toFixed(4);
      setCornBalance(cornAmount);
    },
    // eslint-disable-next-line
    [address, setCornBalance, corn]
  );

  const fetchTransactions = useCallback(
    async (address: string) => {
      let query = `?module=account&action=txlist&address=${address}`;

      if ((page ?? 0) >= 0 && offset) {
        query += `&page=${page}&offset=${offset}`;
      }

      const res = await fetch(`${baseURL}${query}`);
      const transactions = await res.json();

      const formattedTransactions =
        transactions.result.map(transformTransaction);
      setTransactions(formattedTransactions);
    },
    // eslint-disable-next-line
    [setTransactions, contract, page, offset]
  );

  const fetchTransaction = useCallback(
    async (address: string) => {
      let query = `?module=transaction&action=gettxinfo&txhash=${address}`;

      const res = await fetch(`${baseURL}${query}`);
      const transaction = await res.json();

      const formattedTransaction = transformTransaction(transaction.result);
      setTransaction(formattedTransaction);
    },
    // eslint-disable-next-line
    [setTransaction]
  );

  const fetchCeloBalance = useCallback(
    async (address: string) => {
      const query = `?module=account&action=balance&address=${address}`;
      const res = await fetch(`${baseURL}${query}`);
      const balances = await res.json();

      let celoB = unit.fromWei(balances.result || 0, "ether");
      celoB = parseFloat(celoB).toFixed(4);
      setCeloBalance(celoB);
    },
    // eslint-disable-next-line
    [address]
  );

  useEffect(() => {
    setTransactions([]);
    if (address) {
      fetchTransactions(address);
      fetchCeloBalance(address);
      fetchCornBalance(address);
    } else if (contract) {
      fetchTransactions(contractAddresses[contract]);
    }

    if (tx) {
      fetchTransaction(tx);
    }
    // eslint-disable-next-line
  }, [contract, fetchTransactions]);

  return { transactions, celoBalance, cornBalance, transaction };
}
