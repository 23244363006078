import { Card, SubMenu } from "@matintosh/masa-ui";
import { Pagination, Transaction } from "@hide-on-bush-x/masa-ui";
import { useExplorer } from "../../hooks/useExplorer";
import { useNavigate } from "react-router-dom";
import { useRouteQuery } from "../../hooks/use-route-query";
import { useMemo, useState } from "react";
import { useContractKit } from "@celo-tools/use-contractkit";

export function Explorer() {
  const navigate = useNavigate();
  const { address } = useContractKit();
  const [page, setPage] = useState(0);

  const query = useRouteQuery();
  const contract = query.get("contract") ?? "masa";

  const { transactions }: { transactions: any[] } = useExplorer({
    contract: contract as any,
    page: page,
    offset: 10,
  });

  const submenuOptions = useMemo(() => {
    let options = [
      {
        children: "Masa contract",
        selected: contract === "masa" || !contract,
        onClick: () => navigate("/explorer?contract=masa"),
      },
      {
        children: "Stake contract",
        selected: contract === "stake",
        onClick: () => navigate("/explorer?contract=stake"),
      },
    ];

    if (address) {
      options = [
        {
          children: "Me",
          selected: contract === "me",
          onClick: () => navigate(`/explorer/address/${address}`),
        },
        ...options,
      ];
    }

    return options;
  }, [address, contract, navigate]);

  return (
    <div className="w-full p-8">
      <Card>
        <div className="flex flex-row justify-between">
          <h1>Masa Explorer</h1>
          <div>
            <Pagination page={page} totalPages={10} onChange={setPage} />
          </div>
        </div>

        <div>
          <SubMenu options={submenuOptions} />
        </div>
        {transactions.map((item, idx) => (
          <Transaction position={idx} {...item} key={`transaction-${idx}`} />
        ))}
      </Card>
    </div>
  );
}
