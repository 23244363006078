import { Card } from "@matintosh/masa-ui";
import { useExplorer } from "../../hooks/useExplorer";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
export function TransactionPage() {
  const { transaction: txId } = useParams();

  const { transaction } = useExplorer({
    tx: txId,
  });

  const rowStyles = classNames("flex flex-row w-full justify-between ");
  const valueStyles = classNames("text-black");

  return (
    <div className="w-full p-8">
      <Card>
        <div className="flex flex-row justify-between">
          <h1>
            Transaction{" "}
            <span className="text-linkYellow">{transaction?.hashShort}</span>
          </h1>
        </div>

        <div>
          {transaction && (
            <div className="flex flex-col mt-4">
              <div className={rowStyles}>
                <p>
                  <Tippy
                    content={<span>This is the Transaction identifier</span>}
                  >
                    <span>Transaction hash</span>
                  </Tippy>
                </p>
                <p className={valueStyles}>{transaction.hash}</p>
              </div>
              <div className={rowStyles}>
                <p>
                  <Tippy
                    content={
                      <span>
                        Current transaction state: Success, failed or pending
                      </span>
                    }
                  >
                    <span>Result</span>
                  </Tippy>
                </p>
                <p
                  className={
                    transaction.success ? "text-success" : "text-danger"
                  }
                >
                  {transaction.success ? "Success" : "Failed"}
                </p>
              </div>
              <div className={rowStyles}>
                <p>
                  <Tippy
                    content={<span>This is the Transaction identifier</span>}
                  >
                    <span>Block</span>
                  </Tippy>
                </p>
                <p className={valueStyles}>#{transaction.blockNumber}</p>
              </div>
              <div className={rowStyles}>
                <p>
                  <Tippy content={<span>Transaction timestamp</span>}>
                    <span>Timestamp</span>
                  </Tippy>
                </p>
                <p className={valueStyles}>{transaction.timeStamp}</p>
              </div>
              <div className={rowStyles}>
                <p>
                  <Tippy
                    content={<span>This is the Transaction identifier</span>}
                  >
                    <span>From</span>
                  </Tippy>
                </p>
                <p className="text-linkYellow">{transaction.fromHash}</p>
              </div>
              <div className={rowStyles}>
                <p>
                  <Tippy
                    content={<span>This is the Transaction identifier</span>}
                  >
                    <span>To</span>
                  </Tippy>
                </p>
                <p className="text-linkYellow">{transaction.toHash}</p>
              </div>
              <div className={rowStyles}>
                <p>
                  <Tippy
                    content={<span>This is the Transaction identifier</span>}
                  >
                    <span>Transaction fee</span>
                  </Tippy>
                </p>
                <p className={valueStyles}>{transaction.gas} CELO</p>
              </div>
              <hr></hr>
              <div className={rowStyles}>
                <p>
                  <Tippy
                    content={<span>This is the Transaction identifier</span>}
                  >
                    <span>Raw Input</span>
                  </Tippy>
                </p>
                <p className={valueStyles}>{transaction.rawInput}</p>
              </div>
              {transaction.input && (
                <div className={rowStyles}>
                  <p>
                    <Tippy
                      content={<span>This is the Transaction identifier</span>}
                    >
                      <span>Decoded Input</span>
                    </Tippy>
                  </p>
                  <p className={valueStyles}>{transaction.input.name}</p>
                </div>
              )}
              <div className={rowStyles}>
                <h3>Params</h3>
              </div>
              {transaction.input.params.map(
                (p: { name: string; value: string }) => (
                  <div className={rowStyles}>
                    <p>{p.name}</p>
                    <p>{p.value}</p>
                  </div>
                )
              )}
            </div>
          )}
        </div>
      </Card>
    </div>
  );
}
