import React from "react";
import { useContractKit } from "@celo-tools/use-contractkit";

import { TestMasa, StkCorn } from "@masa-finance/smart-contracts";

const testMasaAddress = TestMasa.networks["44787"].address;
const stkCornAddress = StkCorn.networks["44787"].address;

const testMasaAbi = TestMasa.abi;
const stkCornAbi = StkCorn.abi;

const useContracts = () => {
  const { kit } = useContractKit();

  const [corn, setCorn] = React.useState<any>(new kit.web3.eth.Contract(
    testMasaAbi as any,
    testMasaAddress
  ));

  const [stkCorn, setStkCorn] = React.useState<any>(new kit.web3.eth.Contract(
    stkCornAbi as any,
    stkCornAddress
  ));

  const [cUsd, setCUsd] = React.useState<any>();

  React.useEffect(() => {
    kit.contracts.getStableToken().then((result: any) => {
      setCUsd(result);
    });

    setCorn(new kit.web3.eth.Contract(
      testMasaAbi as any,
      testMasaAddress
    ));

    setStkCorn(new kit.web3.eth.Contract(
      stkCornAbi as any,
      stkCornAddress
    ));
  }, [kit]);

  return { corn, stkCorn, cUsd };
}


export default useContracts;