import { TestMasa, StkCorn } from "@masa-finance/smart-contracts";

const abiDecoder = require("abi-decoder");

const testMasaAbi = TestMasa.abi;
const stkCornAbi = StkCorn.abi;

abiDecoder.addABI(testMasaAbi);
abiDecoder.addABI(stkCornAbi);

export const decodeCornTransactionInput = (data: string) => {
  return abiDecoder.decodeMethod(data);
};

export const decodeStakeTransactionInput = (data: string) => {
  return abiDecoder.decodeMethod(data);
};
