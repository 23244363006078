import React from "react";
import { Header } from "@matintosh/masa-ui";
import { NodeRouter } from "./router";
import { Page } from "./components/page";
import { CeloConnector } from "./components/celo-connector";

import "./App.css";

function App() {
  return (
    <div className="App">
      <Header
        name="Welcome!"
        dropdownOptions={[]}
        logoSubtitle="Explorer v0.1"
        action={<CeloConnector />}
      />
      <Page>
        <NodeRouter />
      </Page>
    </div>
  );
}

export default App;
