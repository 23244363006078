import { Routes, Route } from "react-router-dom";
import { Explorer } from "../pages/explorer";
import { ExplorerAddress } from "../pages/explorer-address";
import { TransactionPage } from "../pages/transaction";

export function NodeRouter() {
  return (
    <Routes>
      <Route path="/" element={<Explorer />} />
      <Route path="/explorer">
        <Route element={<Explorer />} index />
        <Route path="address/:address" element={<ExplorerAddress />} />
        <Route path="tx/:transaction" element={<TransactionPage />} />
      </Route>
    </Routes>
  );
}
